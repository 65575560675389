.advantages {
    background-color: var(--white);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-advantages {
    font-family: 'Barlow', sans-serif;
    font-size: 1.1em;
    line-height: 150%;
    font-weight: 300;
    padding: 1em;
    text-align: center;
}
