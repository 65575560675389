.advantages {
  background-color: var(--white);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  box-shadow: 0 0 30px #0003;
}

.text-advantages {
  text-align: center;
  padding: 1em;
  font-family: Barlow, sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 150%;
}

/*# sourceMappingURL=advantages.d2fafadd.css.map */
